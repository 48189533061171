var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "lg:border border-grey-lighter rounded lg:mb-24" },
    [
      !_vm.collapse
        ? _c(
            "div",
            {
              staticClass:
                "block-card lg:mb-24 border-b-1 lg:border-0 border-grey-lighter rounded-sm",
              class: _vm.noPadding ? "" : "py-24 md:p-24",
            },
            [
              _vm.title
                ? _c("h4", { staticClass: "mb-24 font-medium" }, [
                    _vm._v(_vm._s(_vm.title)),
                  ])
                : _vm._e(),
              _vm._t("default"),
            ],
            2
          )
        : _vm._e(),
      _vm.collapse
        ? _c(
            "div",
            {
              staticClass: "my-16 px-24 collapseBlock",
              class: { open: !_vm.isMask },
            },
            [
              _vm.title
                ? _c(
                    "h4",
                    {
                      staticClass: "mb-24 font-medium",
                      on: {
                        click: function ($event) {
                          _vm.isMask = !_vm.isMask
                        },
                      },
                    },
                    [
                      _c("i", { staticClass: "owl-caret-right" }),
                      _vm._v(_vm._s(_vm.title)),
                    ]
                  )
                : _vm._e(),
              _c("div", { staticClass: "collapseBlock__body" }, [
                _c(
                  "p",
                  {
                    staticClass: "text-sm text-grey-darker whitespace-pre-wrap",
                  },
                  [_vm._t("default")],
                  2
                ),
                _c("div", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.isMask,
                      expression: "isMask",
                    },
                  ],
                  staticClass: "collapseBlock__body__mask",
                  on: {
                    click: function ($event) {
                      _vm.isMask = false
                    },
                  },
                }),
              ]),
            ]
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }