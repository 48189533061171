<template>
  <div class='hotel-content'>
    <div
      id='anchor-rooms'
      class="room-types">
      <div class="loading-zone" v-if="this['hotel/isLoadingRooms']">
        <loading />
      </div>
      <template v-for="room in filterRooms">
        <RoomCard
          :key="room.id"
          :room="room"
          v-if="!room.is_whole_building"
          v-on="$listeners"
        >
        </RoomCard>
        <div
          v-else
          :key="`building-${room.id}`"
          id="anchor-building"
          class="book-building mb-40 md:mb-80"
        >
          <BuildingCard :building="building" v-on="$listeners"></BuildingCard>
        </div>
      </template>
    </div>

    <!-- 今日無空房 -->
    <div
      v-if="!filterRooms.length"
      id="no-room-available"
      class="p-24 mb-32 flex flex-col items-center rounded-sm border-1 border-grey-lighter">
      <img src="@/assets/img/hotel-img-grey.png" alt="" class='mb-32'>
      <div class="empty-notice flex items-center">
        <i class='owl-status-triangle-warning text-orange text-2xl'></i>
        <p class='text-2xl font-medium text-grey-darkest'>{{ $t('noRoom') }}</p>
      </div>
    </div>

    <!-- [OW-3172] 應依照API結果排序，而非包棟放最後 -->
    <!--
    <div
      v-if="hasBuilding && !this['hotel/isLoadingRooms']"
      id="anchor-building"
      class="book-building mb-40 md:mb-80">
      <BuildingCard :building="building" v-on="$listeners"></BuildingCard>
    </div>
    -->

    <div id="anchor-intro">
      <div class="hotel-intro mb-40 md:mb-80" v-if="hasHotelFeatures">
        <h4 class='text-grey-darker font-medium text-xl'>{{ $t('hotelFeatures') }}</h4>
        <div
          v-for="(value, key) in this['hotel/hotel'].hotel_features"
          :key="key"
          class="py-24 px-0 mb-24 border-b-1 border-grey-lighter">
          <h5 class="text-grey-darker font-semibold text-base mb-12">
            {{ $t('hotelFeatures_' + key) }}
          </h5>
          <div class="flex flex-wrap">
            <template v-for="feature in value">
              <div
                v-if="!feature.is_charge"
                :key="feature.text"
                class="w-1/4 my-8">
                <div class="flex items-start pr-8">
                  <img
                    v-if="feature.icon_url"
                    :src="feature.icon_url"
                    class="feature__icon">
                  <i
                    v-else-if="feature.name"
                    class="be-icon feature__icon"
                    :class="feature.name" />
                  <h6 class="text-sm ml-8">{{feature.text}}</h6>
                </div>
              </div>
            </template>
          </div>
        </div>
        <div
          v-if="this['hotel/hotel'].chargeFeatures.length > 0"
          class="py-24 px-0 mb-24 border-b-1 border-grey-lighter">
          <h5 class="text-orange-dark text-base font-semibold mb-12">
            {{ $t('hotelFeaturesExtraCharge') }}
          </h5>
          <div class="flex flex-wrap">
            <div
              v-for="feature in this['hotel/hotel'].chargeFeatures"
              :key="feature.text"
              class="w-1/4 my-8">
              <div class="flex items-start pr-8">
              <img
                v-if="feature.icon_url"
                :src="feature.icon_url"
                class="feature__icon-charge">
              <i
                v-else-if="feature.name"
                class="be-icon feature__icon-charge"
                :class="feature.name" />
              <h6 class="text-sm ml-8 text-orange-dark">{{feature.text}}</h6>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="hotel-intro mb-40 md:mb-80" v-if="isDescription">
        <h4 class='mb-16 text-grey-darkest'>{{ $t('hotelInfo') }}</h4>
        <p class="leading-normal text-grey-darkest text-sm whitespace-pre-line word-break-word">
          {{ this['hotel/hotel'].description }}
        </p>
      </div>

      <div class="hotel-contact mb-40 md:mb-80" v-if="this['hotel/hotel'].contact && showContactBlock">
        <h4 class='mb-16 text-grey-darkest'>{{ $t('contactInfo') }}</h4>
        <!-- <div class="hotel-contact__row text-grey-dark text-sm mb-8">
          <h6>{{ this['hotel/hotel'].contact.name }}</h6>
        </div> -->
        <div
          v-if="this['hotel/hotel'].contact.phone && this['hotel/hotelDisplaySetting'].showPhone"
          class="hotel-contact__row text-grey-darkest text-sm mb-16">
          <a :href="`tel:${this['hotel/hotel'].contact.phone }`">
            <i class="owl-phone mr-8"></i>
            <span>{{ this['hotel/hotel'].contact.phone }}</span>
          </a>
        </div>
        <div
          v-if="this['hotel/hotel'].contact.fax"
          class="hotel-contact__row text-grey-darkest text-sm mb-16">
          <a :href="`tel:${this['hotel/hotel'].contact.fax }`">
            <i class="owl-inbox mr-8"></i>
            <span>{{ this['hotel/hotel'].contact.fax }}</span>
          </a>
        </div>
        <div
          v-if="this['hotel/hotel'].contact.email && this['hotel/hotelDisplaySetting'].showEmail"
          class="hotel-contact__row text-grey-darkest text-sm mb-16">
          <a :href="`mailto:${this['hotel/hotel'].contact.email }`">
            <i class="owl-format-mail mr-8"></i>
            <span>{{ this['hotel/hotel'].contact.email }}</span>
          </a>
        </div>
        <div
          v-if="this['hotel/hotelWebSiteUrl'] && this['hotel/hotelDisplaySetting'].showLink"
          class="hotel-contact__row text-grey-darkest text-sm">
          <a :href="this['hotel/hotelWebSiteUrl']" target="_blank">
            <i class="owl-earth mr-8"></i>
            <span>{{ this['hotel/hotel'].name }} {{ $t('website') }}</span>
          </a>
        </div>
      </div>

      <div class="hotel-contact mb-40 md:mb-80" v-if="this['hotel/hotel'].custom_reference.length > 0">
        <h4 class='mb-16 text-grey-darkest'>{{ $t('customReference') }}</h4>
        <div
          class="hotel-contact__row text-grey-darkest text-sm mb-16"
          v-for="item in this['hotel/hotel'].custom_reference"
          :key="`custom_reference__${item.url}`">
          <a :href="item.url" target="_blank">
            <i class="owl-link-intact mr-8"></i>
            {{ item.title }}
          </a>
        </div>
      </div>

      <div class="hotel-location mb-40 md:mb-80">
        <h4 class="mb-16 text-grey-darkest">{{ $t('location') }}</h4>
        <div class="hotel-location__row text-grey-darkest text-sm mb-8">
          <i class="owl-location mr-8"></i>
          <span>{{ this['hotel/hotel'].address }}</span>
        </div>
        <div class="hotel-location__map">
          <iframe v-if="this['hotel/hotelLocatedBy']" :src="`https://www.google.com/maps/embed/v1/place?q=${encodeURI(this['hotel/hotelLocatedBy'])}&key=${googleMapKey}`" width="100%" height="320" frameborder="0" style="border:0" allowfullscreen></iframe>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import _ from 'lodash'
import loading from '@/components/loading'
import RoomCard from '@/views/Hotel/components/roomCard'
import BuildingCard from '@/views/Hotel/components/buildingCard'
import { mapGetters } from 'vuex'

export default {
  name: 'hotel-content',
  components: {
    loading,
    RoomCard,
    BuildingCard
  },
  computed: {
    ...mapGetters([
      'hotel/isLoadingRooms',
      'hotel/hotel',
      'hotel/rooms',
      'hotel/hotelLocatedBy',
      'hotel/hotelWebSiteUrl',
      'hotel/hotelDisplaySetting',
      'search/roomId'
    ]),
    filterRooms () {
      const result = this['hotel/rooms']
      const filter = this['search/roomId']
      return filter
        ? [
          ...result.filter(c => c.id === filter),
          ...result.filter(c => c.id !== filter)
        ]
        : result
    },
    isDescription: function () {
      return !_.isEmpty(this['hotel/hotel'].description)
    },
    hasHotelFeatures: function () {
      return !_.isEmpty(this['hotel/hotel'].hotel_features)
    },
    googleMapKey: function () {
      return process.env.VUE_APP_GOOGLE_MAP_KEY
    },
    hasBuilding: function () {
      const building = _.find(this['hotel/rooms'], room => room.is_whole_building === true)
      return !!building
    },
    building: function () {
      const building = _.find(this['hotel/rooms'], room => room.is_whole_building === true)
      return building
    },
    noRoomAvailable: function () {
      return _.isEmpty(this['hotel/rooms']) && !this.hasBuilding && !this['hotel/isLoadingRooms']
    },
    showContactBlock: function () {
      const list = [
        this['hotel/hotel'].contact.phone && this['hotel/hotelDisplaySetting'].showPhone,
        this['hotel/hotel'].contact.fax,
        this['hotel/hotel'].contact.email && this['hotel/hotelDisplaySetting'].showEmail,
        this['hotel/hotelWebSiteUrl'] && this['hotel/hotelDisplaySetting'].showLink
      ]
      return list.includes(true)
    }
  }
}
</script>

<style lang="scss" scoped>
.hotel-content {
  position: relative;
  ::v-deep .loading-wrap {
    align-items: flex-start;
    .lds-roller {
      margin-top: 3rem;
    }
  }

  #anchor-rooms {
    position: relative;
    .loading {
      top: 0;
      left: 0;
      width: 100%;
      position: absolute;
      padding: 0;
      z-index: 1;
      width: 100%;
      height: 100%;
      min-height: 300px;
      background-color: rgba(255, 255, 255, 0.4);
      margin: 0;
    }
  }
}
</style>

<style lang='sass' scoped>
.word-break-word
  word-break: break-word

.hotel-contact__row
  a
    @apply no-underline text-grey-darkest

.feature__icon
  width: 22px
  &-charge
    width: 22px
    filter: invert(56%) sepia(68%) saturate(2612%) hue-rotate(356deg) brightness(92%) contrast(91%)

</style>
