var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "main",
    { staticClass: "payment-callback mt-40" },
    [
      _c(_vm.paymentTemplate, {
        tag: "component",
        attrs: {
          qrcodeFor: _vm.qrcodeFor,
          qrcodeImg: _vm.qrcodeImg,
          qrcodeUrl: _vm.qrcodeUrl,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }