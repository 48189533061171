var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "py-24 px-0 lg:px-16 border-b-1 border-grey-lighter" },
    [
      _c("div", { staticClass: "order-room-plan relative" }, [
        _c(
          "div",
          {
            staticClass:
              "order-room-plan__head flex flex-row items-start mb-16",
          },
          [
            _vm.showFlexiblePrice
              ? _c(
                  "div",
                  {
                    staticClass:
                      "flexible-type os-tag os-tag-full os-tag-solid text-xs flex-no-shrink",
                    class: _vm.flexibleTagColor,
                  },
                  [_vm._v(_vm._s(_vm.plan.flexible_title))]
                )
              : _vm._e(),
            _c(
              "h5",
              {
                staticClass: "font-medium text-grey-darker mr-8",
                class: { "cursor-pointer": _vm.isNeedCollapse },
                on: {
                  click: function ($event) {
                    _vm.isNeedCollapse && _vm.toggleCollapse()
                  },
                },
              },
              [
                _vm.isNeedCollapse
                  ? _c("i", {
                      staticClass: "owl-caret-right",
                      class: { rotate: !_vm.isCollapse },
                    })
                  : _vm._e(),
                _vm._v(
                  "\n        " +
                    _vm._s(_vm._f("contentParser")(_vm.plan.title)) +
                    "\n      "
                ),
              ]
            ),
          ]
        ),
        _c(
          "div",
          {
            staticClass: "order-room-plan__body flex flex-col md:flex-row",
            class: { isCollapse: _vm.isCollapse && _vm.isNeedCollapse },
          },
          [
            _c(
              "div",
              {
                staticClass:
                  "order-room-plan__content text-sm text-grey-darker leading-normal w-full md:w-3/5",
              },
              [
                _c("p", {
                  ref: "plan-intro",
                  staticClass: "whitespace-pre-wrap mb-32",
                  class: {
                    "cursor-pointer": _vm.isCollapse && _vm.isNeedCollapse,
                  },
                  domProps: { innerHTML: _vm._s(_vm.plan.intro) },
                  on: {
                    click: function ($event) {
                      _vm.isCollapse && _vm.toggleCollapse()
                    },
                  },
                }),
                _vm.isCancelPolicy
                  ? _c(
                      "label",
                      {
                        staticClass: "text-grey-dark cursor-pointer",
                        on: { click: _vm.openCancelPolicy },
                      },
                      [
                        _c("span", [_vm._v(_vm._s(_vm.$t("cancelPolicy")))]),
                        _c("i", { staticClass: "owl-status-circle-info" }),
                      ]
                    )
                  : _vm._e(),
              ]
            ),
          ]
        ),
        _c(
          "div",
          {
            staticClass:
              "card-bottom-block flex flex-col md:flex-row justify-end w-full md:w-auto",
          },
          [
            _c(
              "div",
              {
                staticClass:
                  "order-room-plan__amount w-full flex flex-col justify-between md:justify-end items-end",
              },
              [
                _vm.showFlexiblePrice && _vm.showPriceCross
                  ? _c("div", { staticClass: "mb-4 mr-4 flex flex-wrap" }, [
                      _c(
                        "div",
                        { staticClass: "price--cross font-bold text-xl" },
                        [
                          _vm._v(
                            _vm._s(
                              _vm._f("currency")(_vm.plan.avg_price_without_fee)
                            )
                          ),
                        ]
                      ),
                      _c("small", { staticClass: "invisible" }, [
                        _vm._v(" / " + _vm._s(_vm.$tc("night", 1))),
                      ]),
                    ])
                  : _vm._e(),
                _c(
                  "div",
                  {
                    staticClass:
                      "order-room-plan__amount__row mb-8 w-full flex justify-end md:justify-end items-center",
                  },
                  [
                    _c("div", { staticClass: "per-price text-grey-dark" }, [
                      _c("small", [_vm._v(_vm._s(_vm.$t("about")) + " ")]),
                      _c("small", [_vm._v(_vm._s(_vm.selectedCurrency) + " ")]),
                      _vm.showFlexiblePrice
                        ? _c(
                            "span",
                            {
                              staticClass: "text-blue font-bold",
                              class: {
                                flexiblePriceActive: _vm.showPriceCross,
                              },
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm._f("currency")(
                                    _vm.priceFilterDecimals(
                                      _vm.plan.avg_price_without_fee
                                    )
                                  )
                                )
                              ),
                            ]
                          )
                        : _c("span", { staticClass: "text-blue font-bold" }, [
                            _vm._v(
                              _vm._s(
                                _vm._f("currency")(
                                  _vm.priceFilterDecimals(
                                    _vm.plan.avg_price_without_fee
                                  )
                                )
                              )
                            ),
                          ]),
                      _c("small", [
                        _vm._v(" / " + _vm._s(_vm.$tc("night", 1))),
                      ]),
                    ]),
                  ]
                ),
                _c(
                  "div",
                  { staticClass: "w-full flex items-center justify-end" },
                  [
                    _vm.isBuilding
                      ? _vm._t("buildingButton")
                      : [
                          _c(
                            "div",
                            {
                              staticClass: "flex-auto numberInputWidth",
                              class: {
                                "cursor-not-allowed":
                                  _vm.hasRestrictedReasons ||
                                  _vm.disabledInputByCustomerNumber,
                              },
                              on: {
                                click: function ($event) {
                                  _vm.disabledInputByCustomerNumber &&
                                    _vm.handleShowCustomerSelector()
                                },
                              },
                            },
                            [
                              _c("vue-numeric-input", {
                                attrs: {
                                  min: 0,
                                  max: _vm.remainStock,
                                  step: 1,
                                  disabled:
                                    _vm.hasRestrictedReasons ||
                                    _vm.disabledInputByCustomerNumber,
                                },
                                model: {
                                  value: _vm.number,
                                  callback: function ($$v) {
                                    _vm.number = $$v
                                  },
                                  expression: "number",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            {
                              staticClass:
                                "text-lg text-grey-dark ml-8 font-bold capitalize",
                            },
                            [_vm._v(_vm._s(_vm.room.unit))]
                          ),
                        ],
                  ],
                  2
                ),
                _vm.hasRestrictedReasons
                  ? _c("RestrictTip", {
                      attrs: { restrictedReasons: _vm.plan.restricted_reasons },
                    })
                  : _vm._e(),
                _vm.disabledInputByCustomerNumber
                  ? _c(
                      "div",
                      { staticClass: "text-right text-orange-dark text-sm" },
                      [_vm._v(_vm._s(_vm.$t("blockWithCustomerNumber")))]
                    )
                  : _vm._e(),
              ],
              1
            ),
          ]
        ),
      ]),
      _c(
        "div",
        { staticClass: "toggle-block" },
        [
          _c(
            "div",
            { staticClass: "flex" },
            [
              _vm.showExtraReceptionSetting
                ? _c("Toggle", {
                    staticClass: "ml-auto",
                    attrs: {
                      isChecked: _vm.hasExtraReception,
                      inactiveLabel: _vm.$t(
                        "extraReception.applyExtraReception"
                      ),
                    },
                    on: {
                      "update:isChecked": function ($event) {
                        _vm.hasExtraReception = $event
                      },
                      "update:is-checked": [
                        function ($event) {
                          _vm.hasExtraReception = $event
                        },
                        _vm.handleHasExtraReception,
                      ],
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
          _vm.showExtraReceptionSetting && _vm.hasExtraReception
            ? _vm._l(_vm.number, function (item, index) {
                return _c("ExtraRectptionForm", {
                  key: `extraRectptionForm_${index}`,
                  attrs: {
                    index: index + 1,
                    extraReceptionData: _vm.extraReceptionData[index],
                    adultsOption: _vm.receptionOptions[index].adult,
                    childrenOption: _vm.receptionOptions[index].child,
                  },
                  on: {
                    "update:extraReceptionData": function ($event) {
                      return _vm.$set(_vm.extraReceptionData, index, $event)
                    },
                    "update:extra-reception-data": function ($event) {
                      return _vm.$set(_vm.extraReceptionData, index, $event)
                    },
                    update: _vm.updateReceptionData,
                  },
                })
              })
            : _vm._e(),
        ],
        2
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }