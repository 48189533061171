var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "addon-card__orders" }, [
    _c(
      "div",
      { staticClass: "addon-card__orders__brief md:flex mb-16 w-full" },
      [
        _c("figure", {
          staticClass:
            "block addon-card__orders__brief__cover mr-16 rounded-sm bg-cover bg-center bg-no-repeat",
          style: `background-image: url(${_vm.addonDetail.cover_images[0]})`,
        }),
        _c(
          "div",
          { staticClass: "addon-card__orders__brief__content flex flex-col" },
          [
            _c(
              "div",
              {
                staticClass:
                  "addon-card__orders__brief__content__title mb-8 flex flex-col md:flex-row items-start",
              },
              [
                _c("h5", { staticClass: "text-grey-darker font-medium" }, [
                  _vm._v(_vm._s(_vm.addonName)),
                ]),
              ]
            ),
            _vm._l(_vm.addon.items, function (item, index) {
              return _c(
                "div",
                {
                  key:
                    _vm.addon.id +
                    "-" +
                    _vm.$dayjs(item.date).format("YYYY-MM-DD") +
                    "-" +
                    item.session +
                    "-" +
                    index,
                  staticClass:
                    "addon-card__orders__plan flex flex-col md:flex-row items-center text-sm text-grey-darkest",
                },
                [
                  _c(
                    "h6",
                    {
                      staticClass: "addon-card__orders__plan__datetime mr-auto",
                    },
                    [
                      _c("span", [
                        _vm._v(
                          "\n              " +
                            _vm._s(_vm.displayDate(item)) +
                            "\n            "
                        ),
                      ]),
                      _c(
                        "span",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: !_vm.isGoods(item),
                              expression: "!isGoods(item)",
                            },
                          ],
                        },
                        [
                          _vm._v(
                            "\n              " +
                              _vm._s(_vm.displayTime(item)) +
                              "\n            "
                          ),
                        ]
                      ),
                      _c("span", { staticClass: "mr-auto md:mr-40" }, [
                        _vm._v(
                          "x " + _vm._s(item.qty) + " " + _vm._s(item.unit)
                        ),
                      ]),
                    ]
                  ),
                ]
              )
            }),
          ],
          2
        ),
      ]
    ),
    _c("div", { staticClass: "flex justify-end items-center" }, [
      _c("p", { staticClass: "text-sm font-bold" }, [
        _vm._v(
          "\n      " +
            _vm._s(_vm.selectedCurrency) +
            " " +
            _vm._s(_vm._f("currency")(_vm.addonTotal)) +
            "\n    "
        ),
      ]),
      _c(
        "span",
        {
          staticClass:
            "ml-8 text-grey-dark underline font-normal text-xs cursor-pointer",
          on: { click: _vm.handleShowDetail },
        },
        [_vm._v("\n      " + _vm._s(_vm.$t("detail")) + "\n    ")]
      ),
      _vm.hasExtraTaxFeeConfig
        ? _c("small", { staticClass: "block mt-4 text-right" }, [
            _vm._v("(" + _vm._s(_vm.$t("exclude_tax")) + ")"),
          ])
        : _vm._e(),
    ]),
    _vm.hasCancelPolicy
      ? _c(
          "div",
          { staticClass: "mt-16 collapseBlock", class: { open: !_vm.isMask } },
          [
            _c(
              "h6",
              {
                staticClass: "mb-8",
                on: {
                  click: function ($event) {
                    _vm.isMask = !_vm.isMask
                  },
                },
              },
              [
                _c("i", { staticClass: "owl-caret-right" }),
                _vm._v(_vm._s(_vm.$t("cancelPolicy"))),
              ]
            ),
            _c("div", { staticClass: "collapseBlock__body" }, [
              _c("p", {
                staticClass:
                  "text-sm text-grey-darker leading-normal whitespace-pre-wrap",
                domProps: {
                  innerHTML: _vm._s(
                    _vm.$options.filters.contentParser(
                      this.addon.items[0].cancelPolicy
                    )
                  ),
                },
              }),
              _c("div", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.isMask,
                    expression: "isMask",
                  },
                ],
                staticClass: "collapseBlock__body__mask",
                on: {
                  click: function ($event) {
                    _vm.isMask = false
                  },
                },
              }),
            ]),
          ]
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }