var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "components-room-date-selector" },
    [
      _c(
        "popperMenu",
        {
          class: { active: _vm.visibleValue },
          attrs: {
            visible: _vm.visibleValue,
            "click-outside-close": !_vm.isMobile,
          },
          on: {
            "update:visible": function ($event) {
              _vm.visibleValue = $event
            },
          },
        },
        [
          _c(
            "template",
            { slot: "label" },
            [
              _vm.hasSlot("label")
                ? [_vm._t("label")]
                : [
                    _c(
                      "div",
                      { staticClass: "date-range-selector" },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "date-range-checkin",
                            class: { active: _vm.visibleValue },
                          },
                          [
                            _c("div", { staticClass: "label" }, [
                              _c("i", { staticClass: "owl-format-left" }),
                              _vm._v(" " + _vm._s(_vm.$t("checkIn"))),
                            ]),
                            _c("div", { staticClass: "value" }, [
                              _vm._v(
                                _vm._s(
                                  _vm._f("shortDateFormat")(
                                    _vm.dateRangeValueParseResult.start
                                  )
                                )
                              ),
                            ]),
                          ]
                        ),
                        _c("div", { staticClass: "date-range-split" }, [
                          _vm._v("－"),
                        ]),
                        _c(
                          "div",
                          {
                            staticClass: "date-range-checkout",
                            class: { active: _vm.visibleValue },
                          },
                          [
                            _c("div", { staticClass: "label" }, [
                              _c("i", { staticClass: "owl-format-right" }),
                              _vm._v(" " + _vm._s(_vm.$t("checkOut"))),
                            ]),
                            _c("div", { staticClass: "value" }, [
                              _vm._v(
                                _vm._s(
                                  _vm._f("shortDateFormat")(
                                    _vm.dateRangeValueParseResult.end
                                  )
                                )
                              ),
                            ]),
                          ]
                        ),
                        !_vm.classic
                          ? [
                              _c("div", { staticClass: "room-type-split" }),
                              _c(
                                "div",
                                {
                                  staticClass: "room-type",
                                  class: { active: _vm.visibleValue },
                                },
                                [
                                  _c("div", { staticClass: "label" }, [
                                    _c("i", { staticClass: "owl-grid-three" }),
                                    _vm._v(" " + _vm._s(_vm.$t("roomType"))),
                                  ]),
                                  _c("div", { staticClass: "value" }, [
                                    _vm._v(
                                      "\n                " +
                                        _vm._s(_vm.selectedRooms) +
                                        "\n              "
                                    ),
                                  ]),
                                ]
                              ),
                            ]
                          : _vm._e(),
                      ],
                      2
                    ),
                  ],
            ],
            2
          ),
          _c("template", { slot: "dropdown" }, [
            _c(
              "div",
              {
                ref: "dropdownMenu",
                staticClass: "dropdown-menu flex",
                on: { scroll: _vm.dropdownScrollHandler },
              },
              [
                _c(
                  "div",
                  { staticClass: "dropdown-calender" },
                  [
                    _c("v-date-picker", {
                      ref: "datePicker",
                      staticClass: "calender",
                      attrs: {
                        mode: "range",
                        "is-range": "",
                        "min-date": _vm.minDate || undefined,
                        "max-date": _vm.maxDate || undefined,
                        "min-page": _vm.minPageTemp || _vm.minPage,
                        "max-page": _vm.maxPageTemp || undefined,
                        "disabled-dates":
                          _vm.disabledDatesTemp || _vm.disabledDates,
                      },
                      on: {
                        "update:from-page": _vm.onDatePickerUpdate,
                        input: _vm.handleCustomerSelectorOpen,
                        drag: _vm.dayDrag,
                      },
                      model: {
                        value: _vm.dateRangeValue,
                        callback: function ($$v) {
                          _vm.dateRangeValue = $$v
                        },
                        expression: "dateRangeValue",
                      },
                    }),
                    !_vm.classic
                      ? _c("div", { staticClass: "toolbar" }, [
                          _c("div", [
                            _vm.dateRangeText
                              ? _c("b", {
                                  domProps: {
                                    innerHTML: _vm._s(_vm.dateRangeText),
                                  },
                                })
                              : _c("b", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.diffDays > 0
                                        ? _vm.totalDays
                                        : _vm.$t("pleaseSelectDate")
                                    )
                                  ),
                                ]),
                          ]),
                          _c("div", [
                            _c("small", [_vm._v(_vm._s(_vm.calendarNote))]),
                          ]),
                          _c("div", { staticClass: "buttons row pt-2" }, [
                            _c("div", { staticClass: "close-button col" }, [
                              _c(
                                "button",
                                {
                                  staticClass:
                                    "btn btn-link p-0 dropdown-room-clear",
                                  attrs: {
                                    disabled:
                                      !_vm.roomDatesSelectedClearVisible,
                                  },
                                  on: {
                                    click: _vm.roomDatesSelectedClearHandler,
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n                  " +
                                      _vm._s(_vm.$t("cleanAll")) +
                                      "\n                "
                                  ),
                                ]
                              ),
                            ]),
                            _c("div", { staticClass: "submit-button col" }, [
                              _c(
                                "button",
                                {
                                  staticClass: "btn btn-primary w-80",
                                  attrs: {
                                    disabled: _vm.disabledDateRoomPickerConfirm,
                                  },
                                  on: {
                                    click: _vm.dateRoomPickerConfirmHandler,
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n                 " +
                                      _vm._s(_vm.$t("confirm")) +
                                      "\n                "
                                  ),
                                ]
                              ),
                            ]),
                          ]),
                        ])
                      : _vm._e(),
                  ],
                  1
                ),
                !_vm.classic
                  ? _c("div", { staticClass: "dropdown-room" }, [
                      _vm.isMobile
                        ? _c("div", { staticClass: "split-bar" })
                        : _vm._e(),
                      _c("h5", { staticClass: "pl-2 pb-2" }, [
                        _c("div", [
                          _c("b", [
                            _vm._v(_vm._s(_vm.$t("roomTypeFilter")) + "："),
                          ]),
                        ]),
                        _c(
                          "div",
                          { staticClass: "pt-2" },
                          [
                            _vm.roomSelected.length
                              ? [_vm._v(_vm._s(_vm.$t("selected")))]
                              : [_vm._v(_vm._s(_vm.$t("allRoomType")))],
                          ],
                          2
                        ),
                      ]),
                      _c(
                        "div",
                        {
                          ref: "dropdownRoomList",
                          class: [
                            "dropdown-room-list",
                            { disabled: _vm.isDragging },
                          ],
                          on: { scroll: _vm.dropdownRoomListScrollHandler },
                        },
                        [
                          !_vm.hotelRoomsType.length
                            ? [
                                _c("div", { staticClass: "row middle h-90" }, [
                                  _c(
                                    "div",
                                    { staticClass: "col-12 text-center" },
                                    [
                                      _c("img", {
                                        attrs: {
                                          src: require("@/assets/img/hotel-img-nodata.svg"),
                                          alt: "hotelNotFound",
                                        },
                                      }),
                                      _c("h5", { staticClass: "py-1" }, [
                                        _c("b", [
                                          _vm._v(_vm._s(_vm.$t("todayNoData"))),
                                        ]),
                                      ]),
                                      _c("small", [
                                        _vm._v(
                                          _vm._s(_vm.$t("selectOtherDate"))
                                        ),
                                      ]),
                                    ]
                                  ),
                                ]),
                              ]
                            : [
                                _c(
                                  "ul",
                                  { ref: "dropdownRoomListUl" },
                                  _vm._l(_vm.hotelRoomsType, function (v, i) {
                                    return _c(
                                      "li",
                                      {
                                        key: i,
                                        class: {
                                          active: _vm.isRoomActive(v.id),
                                          disabled:
                                            !_vm.enabledRoomIds.includes(+v.id),
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.dropdownRoomListClickHandler(
                                              v
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "flex middle" },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "dropdown-room-list-image",
                                              },
                                              [
                                                _c("img", {
                                                  attrs: {
                                                    src: _vm.imageParse(v),
                                                  },
                                                }),
                                                _vm.isRoomActive(v.id)
                                                  ? [
                                                      _c("i", {
                                                        staticClass:
                                                          "owl-status-check checked-icon",
                                                      }),
                                                    ]
                                                  : _vm._e(),
                                              ],
                                              2
                                            ),
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "dropdown-room-list-label pl-3",
                                              },
                                              [
                                                _vm._v(
                                                  "\n                      " +
                                                    _vm._s(v.name) +
                                                    "\n                    "
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ]
                                    )
                                  }),
                                  0
                                ),
                                _c("i", {
                                  staticClass:
                                    "owl-caret-bottom dropdown-room-list-scroll-to",
                                  class: { "scroll-bottom": _vm.scrollBottom },
                                  on: { click: _vm.scrollToBottomClickHandler },
                                }),
                              ],
                        ],
                        2
                      ),
                    ])
                  : _vm._e(),
              ]
            ),
            !_vm.classic
              ? _c("div", { staticClass: "toolbar" }, [
                  _c("i", {
                    staticClass:
                      "owl-caret-bottom dropdown-room-list-scroll-to",
                    class: { "scroll-bottom": _vm.scrollBottom },
                    on: { click: _vm.scrollToBottomClickHandler },
                  }),
                  _c("div", { staticClass: "buttons row pt-2" }, [
                    _c("div", { staticClass: "close-button col-auto" }, [
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-outline-grey",
                          on: { click: _vm.dateRoomPickerCloseHandler },
                        },
                        [
                          _vm._v(
                            "\n              " +
                              _vm._s(_vm.$t("close")) +
                              "\n            "
                          ),
                        ]
                      ),
                    ]),
                    _c("div", { staticClass: "col text-right" }, [
                      _vm.roomDatesSelectedClearVisible
                        ? _c(
                            "button",
                            {
                              staticClass: "btn btn-link dropdown-room-clear",
                              on: {
                                click: function ($event) {
                                  if ($event.target !== $event.currentTarget)
                                    return null
                                  return _vm.roomDatesSelectedClearHandler.apply(
                                    null,
                                    arguments
                                  )
                                },
                              },
                            },
                            [
                              _vm._v(
                                "\n              " +
                                  _vm._s(_vm.$t("cleanAll")) +
                                  "\n            "
                              ),
                            ]
                          )
                        : _vm._e(),
                    ]),
                    _c("div", { staticClass: "submit-button col-auto" }, [
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-primary",
                          attrs: {
                            disabled: _vm.disabledDateRoomPickerConfirm,
                          },
                          on: { click: _vm.dateRoomPickerConfirmHandler },
                        },
                        [
                          _vm._v(
                            "\n              " +
                              _vm._s(_vm.$t("confirm")) +
                              "\n            "
                          ),
                        ]
                      ),
                    ]),
                  ]),
                ])
              : _vm._e(),
          ]),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }