<template>
  <div
    id="cookie-notification"
    v-if="!hasAgreeCookie">
    <div class="container flex flex-col sm:flex-row items-end sm:items-center">
      <div class="content mb-8 sm:mb-0 sm:mr-32">
        <!-- <strong>OwlNest使用者隱私權聲明</strong> -->
        <p>{{ $t('cookie.content') }}
          <a href="https://www.owlting.com/about/cookie_policy" target="_blank">{{ $t('cookie.more') }}</a>
        </p>
      </div>
      <button
        class="btn btn-sm btn-solid btn-blue flex-no-shrink px-16"
        @click="agreeCookie">{{ $t('cookie.agree') }}</button>
    </div>
  </div>
</template>

<script>
import { mapMutations, mapGetters } from 'vuex'
export default {
  name: 'cookie-notification',
  computed: {
    ...mapGetters([
      'hasAgreeCookie'
    ])
  },
  methods: {
    ...mapMutations([
      'SET_COOKIE'
    ]),
    agreeCookie: function () {
      document.cookie = 'owlnestBE-agree-cookie=true'
      this.SET_COOKIE()
    }
  }
}
</script>

<style lang="sass" scoped>
#cookie-notification
  @apply text-sm text-white fixed pin-b pin-l w-full leading-normal py-8
  z-index: 1010
  background: rgba(0, 0, 0, .75)
  .content
    a
      @apply text-white underline
  button
    @apply bg-blue-light text-sm
    letter-spacing: .1rem
    &:hover
      @apply bg-blue text-white
</style>
