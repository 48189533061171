var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "modal__content-width-cover rounded-lg" }, [
    _vm.isCoverImage
      ? _c("div", {
          staticClass: "modal__content-width-cover__cover",
          style: { backgroundImage: `url(${_vm.img})` },
        })
      : _vm._e(),
    _c("div", { staticClass: "modal__content-width-cover__content p-24" }, [
      _vm.isFeatures
        ? _c(
            "div",
            { staticClass: "modal__content-width-cover__content__features" },
            [
              _c("h5", { staticClass: "text-grey-darkest mb-16" }, [
                _vm._v(_vm._s(_vm.$tc("features", _vm.features.length))),
              ]),
              _c(
                "ul",
                { staticClass: "mb-40" },
                _vm._l(_vm.features, function (feature, index) {
                  return _c(
                    "li",
                    {
                      key: index,
                      staticClass: "mb-16 text-sm text-grey-darkest w-1/2",
                    },
                    [
                      feature.icon_url
                        ? _c("img", {
                            staticClass: "modal__feature-icon",
                            attrs: { src: feature.icon_url },
                          })
                        : feature.name
                        ? _c("i", {
                            staticClass: "be-icon",
                            class: feature.name,
                          })
                        : _vm._e(),
                      _c("span", { staticClass: "align-text-top" }, [
                        _vm._v(
                          "\n           " +
                            _vm._s(feature.text) +
                            "\n          "
                        ),
                      ]),
                    ]
                  )
                }),
                0
              ),
            ]
          )
        : _c(
            "div",
            {
              staticClass: "modal__content-width-cover__content__cancel-policy",
            },
            [
              _c(
                "h5",
                {
                  staticClass:
                    "modal__content-width-cover__content__title text-grey-darkest mb-16",
                  style: _vm.titleStyle,
                },
                [
                  _vm.icon
                    ? _c("i", {
                        staticClass:
                          "modal__content-width-cover__content__title__iconStyle",
                        class: _vm.iconStyle,
                      })
                    : _vm._e(),
                  _vm._v("\n        " + _vm._s(_vm.title) + "\n      "),
                ]
              ),
              _c("p", {
                staticClass: "text-sm text-grey-darker leading-normal",
                class: _vm.isHtml
                  ? "whitespace-no-wrap"
                  : "whitespace-pre-wrap",
                domProps: { innerHTML: _vm._s(_vm.context) },
              }),
            ]
          ),
    ]),
    _c(
      "div",
      { staticClass: "modal__content-width-cover__footer flex w-full" },
      [
        _vm.isCustomButtons
          ? _vm._l(_vm.buttons, function (btn, index) {
              return _c(
                "div",
                {
                  key: index,
                  staticClass:
                    "flex-1 border-t-1 border-grey-lighter text-center text-grey py-16 cursor-pointer",
                  on: {
                    click: function ($event) {
                      return _vm.methodHandler(btn)
                    },
                  },
                },
                [_vm._v("\n        " + _vm._s(btn.title) + "\n      ")]
              )
            })
          : _c(
              "div",
              {
                staticClass:
                  "flex-1 border-t-1 border-grey-lighter text-center text-grey py-16 cursor-pointer",
                on: {
                  click: function ($event) {
                    return _vm.$emit("close")
                  },
                },
              },
              [_vm._v("\n      " + _vm._s(_vm.$t("close")) + "\n    ")]
            ),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }