var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "component-message-button" }, [
    _vm.$slots.tooltip
      ? _c("div", { class: ["tooltip", { active: _vm.tooltipShow }] }, [
          _c("div", { staticClass: "content" }, [_vm._t("tooltip")], 2),
          _c("div", { staticClass: "arrow" }),
        ])
      : _vm._e(),
    _c(
      "div",
      { class: ["message-buttons", { active: this.visible }] },
      _vm._l(_vm.data, function (v, i) {
        return _c(
          "a",
          {
            key: i,
            staticClass: "chat-button",
            style: {
              background: v.background,
              backgroundSize: "cover",
              backgroundPosition: "center",
              color: v.color || "#fff",
            },
            on: {
              click: function ($event) {
                return _vm.hrefClickHandler(v.link)
              },
            },
          },
          [v.icon ? _c("i", { class: v.icon }) : _vm._e()]
        )
      }),
      0
    ),
    _c("div", {
      class: ["main-button", { active: this.visible }],
      on: { click: _vm.toggleButtonsClickHandler },
    }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }