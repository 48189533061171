var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "payment-callback__default-template" }, [
    _c(
      "div",
      { staticClass: "container" },
      [
        _c("blockCard", [
          _c(
            "h2",
            {
              staticClass:
                "text-orange mb-16 font-medium text-center sm:text-left",
            },
            [_vm._v(_vm._s(_vm.$t("orderNotCompleted")))]
          ),
          _c("div", { staticClass: "notice" }, [
            _c("i", {
              staticClass:
                "owl-status-triangle-warning text-orange text-xl mr-8",
            }),
            _c("span", { staticClass: "text-sm text-grey-darkest" }, [
              _vm._v(_vm._s(_vm.$t("waitingForPaid`"))),
            ]),
          ]),
        ]),
        _c(
          "div",
          {
            staticClass:
              "btn-group flex mt-16 sm:mt-40 mb-40 justify-center sm:justify-start",
          },
          [
            _c("button", { staticClass: "btn btn-solid btn-blue mr-16" }, [
              _vm._v(_vm._s(_vm.$t("repay"))),
            ]),
            _c("button", { staticClass: "btn btn-ghost btn-blue" }, [
              _vm._v(_vm._s(_vm.$t("cancelPay"))),
            ]),
          ]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }