var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "order-total lg:sticky",
      style: {
        top:
          _vm.pageIsHotelOrAddon === "Addon"
            ? _vm.addonStyle.top
            : _vm.hotelStyle.top,
      },
    },
    [
      _c(
        "aside",
        {
          staticClass:
            "order-total__desktop shadow-md border-1 border-grey-lightest rounded-sm hidden lg:block",
        },
        [
          _c(
            "div",
            {
              staticClass:
                "order-total__desktop__head text-grey-darkest px-16 py-24 border-b-1 border-grey-lighter",
            },
            [
              _c(
                "div",
                { staticClass: "order-total__desktop__head__row mb-16" },
                [
                  _c("i", {
                    staticClass: "owl-calendar mr-8 text-grey text-xl",
                  }),
                  _c("span", [
                    _vm._v(
                      _vm._s(
                        _vm._f("dayFormat")(this["search/dateRange"].start)
                      )
                    ),
                  ]),
                  _c("span", [_vm._v(" - ")]),
                  _c("span", [
                    _vm._v(
                      _vm._s(_vm._f("dayFormat")(this["search/dateRange"].end))
                    ),
                  ]),
                  _c("small", { staticClass: "ml-8 text-grey-dark" }, [
                    _vm._v(
                      "( " +
                        _vm._s(_vm.diffDays + 1) +
                        " " +
                        _vm._s(_vm.$tc("day", _vm.diffDays + 1)) +
                        " " +
                        _vm._s(_vm.diffDays) +
                        " " +
                        _vm._s(_vm.$tc("night", _vm.diffDays)) +
                        " )"
                    ),
                  ]),
                ]
              ),
              _c("div", { staticClass: "order-total__desktop__head__row" }, [
                _c("i", {
                  staticClass: "owl-user-group mr-8 text-grey text-xl",
                }),
                _c("span", [
                  _vm._v(
                    _vm._s(this["search/people"].adult) +
                      " " +
                      _vm._s(_vm.$tc("adult", this["search/people"].adult))
                  ),
                ]),
                this["hotel/hotelReceptionConfig"].child.isReceive
                  ? _c("span", [
                      _vm._v(
                        ", " +
                          _vm._s(this["search/people"].child) +
                          " " +
                          _vm._s(_vm.$tc("child", this["search/people"].child))
                      ),
                    ])
                  : _vm._e(),
                this["hotel/hotelReceptionConfig"].infant.isReceive
                  ? _c("span", [
                      _vm._v(
                        ", " +
                          _vm._s(this["search/people"].infant) +
                          " " +
                          _vm._s(
                            _vm.$tc("infant", this["search/people"].infant)
                          )
                      ),
                    ])
                  : _vm._e(),
              ]),
            ]
          ),
          _c(
            "div",
            {
              staticClass: "order-total__desktop__body",
              style: {
                maxHeight:
                  _vm.pageIsHotelOrAddon === "Addon"
                    ? _vm.addonStyle.maxHeight
                    : _vm.hotelStyle.maxHeight,
              },
            },
            [
              _c(
                "SlideXLeftTransition",
                { attrs: { group: "", duration: 200 } },
                [
                  _vm._l(
                    this["order/addons"].slice().reverse(),
                    function (addon) {
                      return _c("OrderAddon", {
                        key: addon.id,
                        attrs: { addon: addon },
                      })
                    }
                  ),
                  _vm._l(
                    this["order/plans"].slice().reverse(),
                    function (plan) {
                      return _c("OrderPlan", {
                        key: plan.uid,
                        attrs: { plan: plan },
                      })
                    }
                  ),
                ],
                2
              ),
            ],
            1
          ),
          _vm.hasOrderItems
            ? [
                _c(
                  "div",
                  {
                    staticClass:
                      "order-total__desktop__footer px-16 py-24 border-t-1 border-grey-lighter",
                  },
                  [
                    _vm.hasExtraTaxFeeConfig
                      ? [
                          _c(
                            "div",
                            {
                              staticClass:
                                "order-total__desktop__footer__row text-sm text-grey-darker",
                            },
                            [
                              _c("label", [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(_vm.$t("extraFeesCharge")) +
                                    "\n              "
                                ),
                                _c("i", {
                                  directives: [
                                    {
                                      name: "tippy",
                                      rawName: "v-tippy",
                                      value: {
                                        html: "#extra-fees-list",
                                        interactive: true,
                                        theme: "light",
                                      },
                                      expression:
                                        "{ html: '#extra-fees-list', interactive : true, theme: 'light' }",
                                    },
                                  ],
                                  staticClass: "owl-status-circle-info",
                                }),
                                _c(
                                  "div",
                                  {
                                    directives: [
                                      {
                                        name: "tippy-html",
                                        rawName: "v-tippy-html",
                                      },
                                    ],
                                    staticClass: "hidden",
                                    attrs: { id: "extra-fees-list" },
                                  },
                                  _vm._l(_vm.extraFees, function (fee) {
                                    return _c(
                                      "div",
                                      {
                                        key: `fee-config-${fee.id}`,
                                        staticClass:
                                          "text-sm text-grey-darker flex",
                                      },
                                      [
                                        _c("label", [
                                          _vm._v(_vm._s(fee.name) + ": "),
                                        ]),
                                        _c("span", [
                                          _vm._v(
                                            _vm._s(_vm.selectedCurrency) +
                                              " " +
                                              _vm._s(
                                                _vm._f("currency")(fee.price)
                                              )
                                          ),
                                        ]),
                                      ]
                                    )
                                  }),
                                  0
                                ),
                              ]),
                              _c(
                                "span",
                                [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(_vm.selectedCurrency) +
                                      " "
                                  ),
                                  _c(
                                    "big",
                                    { staticClass: "text-blue font-bold" },
                                    [
                                      _vm._v(
                                        _vm._s(_vm._f("currency")(_vm.totalFee))
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ]
                          ),
                        ]
                      : _vm._e(),
                    _c(
                      "div",
                      {
                        staticClass:
                          "order-total__desktop__footer__row text-grey-darkest",
                      },
                      [
                        _c("label", { staticClass: "text-grey-darkest" }, [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.$t("total")) +
                              "\n            "
                          ),
                          !_vm.hasExtraTaxFeeConfig
                            ? _c("small", [
                                _vm._v(
                                  "(" +
                                    _vm._s(
                                      _vm.$t("room_price_has_tax_included")
                                    ) +
                                    ")"
                                ),
                              ])
                            : _vm._e(),
                        ]),
                        _c(
                          "span",
                          { staticClass: "text-sm text-grey-darkest" },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(_vm.selectedCurrency) +
                                " "
                            ),
                            _c(
                              "big",
                              { staticClass: "text-xl text-blue font-medium" },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm._f("currency")(this["order/totalPrice"])
                                  )
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                      ]
                    ),
                    this["order/ifDepositRate"]
                      ? [
                          _c(
                            "div",
                            {
                              staticClass:
                                "order-total__desktop__footer__row text-xs text-grey-dark",
                            },
                            [
                              _c("label", [
                                _vm._v(_vm._s(_vm.$t("depositTotal"))),
                              ]),
                              _c("span", [
                                _vm._v(
                                  _vm._s(_vm.selectedCurrency) +
                                    " " +
                                    _vm._s(
                                      _vm._f("currency")(
                                        this["order/depositTotal"]
                                      )
                                    )
                                ),
                              ]),
                            ]
                          ),
                          _c(
                            "div",
                            {
                              staticClass:
                                "order-total__desktop__footer__row text-xs text-grey-dark",
                            },
                            [
                              _c("label", [
                                _vm._v(_vm._s(_vm.$t("remainTotal"))),
                              ]),
                              _c("span", [
                                _vm._v(
                                  _vm._s(_vm.selectedCurrency) +
                                    " " +
                                    _vm._s(
                                      _vm._f("currency")(
                                        this["order/remainTotal"]
                                      )
                                    )
                                ),
                              ]),
                            ]
                          ),
                        ]
                      : _vm._e(),
                    _c(
                      "div",
                      { staticClass: "order-total__desktop__footer__row" },
                      [
                        _vm.step !== 1
                          ? _c(
                              "button",
                              {
                                staticClass:
                                  "pre-step btn btn-lg text-grey flex-no-shrink mr-16",
                                on: { click: _vm.preStep },
                              },
                              [_vm._v(_vm._s(_vm.$t("pre")))]
                            )
                          : _vm._e(),
                        _c(
                          "button",
                          {
                            staticClass: "btn btn-lg btn-solid btn-blue w-full",
                            on: { click: _vm.nextStep },
                          },
                          [_vm._v(_vm._s(_vm.$t("next")))]
                        ),
                      ]
                    ),
                  ],
                  2
                ),
              ]
            : _vm._e(),
        ],
        2
      ),
      _c("SlideYDownTransition", { attrs: { duration: 150 } }, [
        _vm.hasOrderItems
          ? _c("div", { staticClass: "order-total__mobile block lg:hidden" }, [
              _c(
                "div",
                {
                  staticClass:
                    "container flex items-end justify-between px-16 py-8",
                },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "order-total__mobile__amount flex flex-col cursor-pointer w-full",
                      class: { isAdding: _vm.isAdding, isMinus: _vm.isMinus },
                      on: { click: _vm.openOrdersModal },
                    },
                    [
                      _c(
                        "label",
                        {
                          staticClass:
                            "text-grey-dark text-sm mb-8 cursor-pointer",
                        },
                        [
                          _c("span", [_vm._v(_vm._s(_vm.$t("orderTotal")))]),
                          _c("i", { staticClass: "owl-status-circle-info" }),
                        ]
                      ),
                      _c(
                        "span",
                        { staticClass: "text-sm text-grey-darkest" },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.selectedCurrency) +
                              " "
                          ),
                          _c(
                            "big",
                            { staticClass: "text-lg text-blue font-medium" },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm._f("currency")(this["order/totalPrice"])
                                )
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ]
                  ),
                  _c(
                    "div",
                    {
                      staticClass:
                        "order-total__mobile__btn-group flex flex-no-shrink",
                    },
                    [
                      _vm.step !== 1
                        ? _c(
                            "button",
                            {
                              staticClass:
                                "pre-step btn text-grey flex-no-shrink mr-16",
                              on: { click: _vm.preStep },
                            },
                            [_vm._v(_vm._s(_vm.$t("pre")))]
                          )
                        : _vm._e(),
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-solid btn-blue w-full px-32",
                          on: { click: _vm.nextStep },
                        },
                        [_vm._v(_vm._s(_vm.$t("next")))]
                      ),
                    ]
                  ),
                ]
              ),
            ])
          : _vm._e(),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }