<template>
  <div :id="swipeContainerSelector" />
</template>

<script>
import PhotoSwipeLightbox from 'photoswipe/dist/photoswipe-lightbox.esm.js'
import PhotoSwipe from 'photoswipe/dist/photoswipe.esm.js'
import 'photoswipe/dist/photoswipe.css'
import { mapMutations, mapGetters } from 'vuex'

export default {
  name: 'photoSwipe',
  props: {
    photos: {
      type: Array,
      required: true
    }
  },
  data () {
    return {
      lightbox: null,
      gallery: '',
      options: {
        pswpModule: PhotoSwipe,
        wheelToZoom: true,
        mouseMovePan: true,
        initialZoomLevel: 'fit',
        secondaryZoomLevel: 1.5,
        showHideAnimationType: 'zoom',
        preload: [1, 1],
        gallery: ''
      }
    }
  },
  computed: {
    ...mapGetters([
      'photoDimensions'
    ]),
    swipeContainerSelector () {
      const firstPhotoName = (this.photos?.[0] || '')
        .split('/')
        .reverse()[0]
        .split('.')[0]

      return `swipe-container-${firstPhotoName || 'default'}`
    }
  },
  methods: {
    ...mapMutations([
      'APPEND_PHOTO_DIMENSION'
    ]),
    /*
    // [DEPRECATED] OW-3357, use lazy load
    checkPhotos: async function () {
      const photoList = []
      for (const photoIndex in this.photos) {
        const url = this.photos[photoIndex]
        const image = new Image()
        let photoDimension = this.photoDimensions[url]

        if (!photoDimension) {
          image.src = url
          await image.decode()
          photoDimension = {
            src: url,
            width: image.width,
            height: image.height
          }
          this.APPEND_PHOTO_DIMENSION({ [`${url}`]: photoDimension })
        }

        photoList.push({
          ...photoDimension,
          src: url
        })
      }
      return photoList
    },
    */
    updateImageDimensions: async function (index) {
      try {
        const url = this.photos[index]
        let photoDimension = this.photoDimensions[url]
        if (!photoDimension && url) {
          const image = new Image()
          image.src = url
          await image.decode()
          photoDimension = {
            width: image.width,
            height: image.height
          }
          this.APPEND_PHOTO_DIMENSION({ [`${url}`]: photoDimension })
        }
      } catch (err) {
        console.warn('[updateImageDimensions failed]', err)
      }
    },
    initLightBox: async function () {
      const lightbox = new PhotoSwipeLightbox(this.options)
      this.lightbox = lightbox

      lightbox.on('close', () => { this.$emit('close') })

      // Prfetch next 2 images dimension
      lightbox.on('contentLoad', ({ content: { index } }) => {
        this.preFetchImagesDimensions([
          (index + 1) % this.photos.length, // next 1 index
          (index + 2) % this.photos.length // next 2 index
        ])
      })

      // Pre-define images list length without specifying any url.
      lightbox.addFilter('numItems', () => this.photos.length)

      // Bind image from dimension dictionary in vuex store.
      lightbox.addFilter('itemData', (...arg) => {
        const url = this.photos[arg[1]]
        const dimension = this.photoDimensions[url]

        return {
          src: url,
          width: dimension?.width,
          height: dimension?.height
        }
      })

      lightbox.init()
    },
    open: function (info) {
      if (info) {
        const { index, x, y, w } = info

        this.options.index = index
        this.options.getThumbBoundsFn = () => {
          return {
            x: x,
            y: y,
            w: w
          }
        }
      }
      if (info) {
        this.lightbox.loadAndOpen(info.index || 0, [], info)
      } else {
        this.lightbox.loadAndOpen(0)
      }
    },
    async preFetchImagesDimensions (indexList = []) {
      await Promise.all(
        ([...new Set(indexList)]).map(
          async index => {
            await this.updateImageDimensions(index)
          }
        )
      )
    }
  },
  async mounted () {
    this.options.gallery = this.swipeContainerSelector
    await this.preFetchImagesDimensions([0, 1, this.photos.length - 1])
    this.initLightBox()
  }
}
</script>

<style lang="scss">
.pswp img {
  object-fit: contain;
  max-height: 95vh;
  max-width: 95vw;
}
</style>
