var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "addon-order__item" }, [
    _c(
      "div",
      {
        staticClass:
          "addon-order__item__head px-16 py-24 border-b-1 border-grey-lighter",
      },
      [
        _c("h5", { staticClass: "text-darkest mb-16" }, [
          _vm._v(_vm._s(_vm._f("contentParser")(_vm.addonName))),
        ]),
        _c(
          "div",
          {
            staticClass:
              "addon-order__item__head__row flex flex-col sm:flex-row justify-between items-start text-grey-dark",
          },
          [
            _c("div", {
              staticClass:
                "addon-order__item__head__row__content mb-24 sm:mb-0",
            }),
            _c(
              "div",
              {
                staticClass:
                  "addon-order__item__head__row__amount w-full sm:w-auto flex justify-between sm:justify-end items-center text-sm flex-wrap",
              },
              [
                _c("label", { staticClass: "mr-32" }, [
                  _vm._v(_vm._s(_vm.$t("total")) + ": "),
                ]),
                _c(
                  "span",
                  { staticClass: "text-xs" },
                  [
                    _vm._v(_vm._s(_vm.orderInfo.currency) + " "),
                    _c(
                      "big",
                      { staticClass: "text-base text-blue font-medium" },
                      [_vm._v(_vm._s(_vm._f("numeral")(_vm.addon.total)))]
                    ),
                  ],
                  1
                ),
                _vm.isIncludesTaxFee
                  ? _c("span", { staticClass: "w-full text-right mt-8" }, [
                      _vm._v(
                        "(" + _vm._s(_vm.$t("price_has_tax_included")) + ")"
                      ),
                    ])
                  : _vm._e(),
              ]
            ),
          ]
        ),
      ]
    ),
    _c(
      "div",
      { staticClass: "addon-order__item__body px-16" },
      _vm._l(_vm.addon.group, function (group, index) {
        return _c(
          "div",
          {
            key: index,
            staticClass:
              "addon-order__item__body__row py-16 flex text-grey-darkest text-sm flex justify-between",
          },
          [
            _c("span", { staticClass: "mr-auto flex-1" }, [
              _c("span", [
                _vm._v(_vm._s(group.date) + " " + _vm._s(group.session)),
              ]),
            ]),
            _vm.addon.unit
              ? _c("span", { staticClass: "mr-40" }, [
                  _vm._v(
                    _vm._s(group.qty) +
                      " " +
                      _vm._s(
                        _vm.$convertI18nObj(_vm.addon.unit_i18n, _vm.addon.unit)
                      )
                  ),
                ])
              : _c("span", { staticClass: "mr-40" }, [
                  _vm._v(_vm._s(group.qty) + " 人"),
                ]),
            _c("span", [
              _vm._v(
                _vm._s(_vm.orderInfo.currency) +
                  " " +
                  _vm._s(_vm._f("numeral")(group.price * group.qty))
              ),
            ]),
          ]
        )
      }),
      0
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }