var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass:
        "cover-loading px-40 py-24 bg-white rounded text-grey-darkest",
    },
    [
      _c("i", { staticClass: "owl-arrow-update icon-loading" }),
      _c("span", { staticClass: "leading-normal" }, [
        _vm._v(_vm._s(_vm.$t("msg_wait_loading"))),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }