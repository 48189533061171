var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("nav", { staticClass: "hotelNav" }, [
    _c(
      "div",
      { staticClass: "left" },
      [
        _c("router-link", { attrs: { to: { name: "Hotel" } } }, [
          _c(
            "h1",
            { staticClass: "py-8 text-2xl text-grey-darkest line-ellipsis" },
            [
              _vm._v(
                "\n        " + _vm._s(this["hotel/hotel"].name) + "\n      "
              ),
            ]
          ),
        ]),
      ],
      1
    ),
    _c("div", { staticClass: "right" }, [
      _c("div", { staticClass: "nav-dropdown language" }, [
        _c(
          "div",
          { staticClass: "nav-dropdown__display" },
          [
            _c("i", { staticClass: "owl-earth" }),
            !_vm.isMobile
              ? [
                  _vm._v(
                    "\n          " + _vm._s(_vm.displayLanguage) + "\n        "
                  ),
                ]
              : _vm._e(),
          ],
          2
        ),
        _c(
          "div",
          { staticClass: "nav-dropdown__list" },
          _vm._l(_vm.langs, function (lang) {
            return _c(
              "li",
              {
                key: lang.code,
                class: { actived: _vm.selectedLanguage === lang.code },
                on: {
                  click: function ($event) {
                    return _vm.onSelectLang(lang.code)
                  },
                },
              },
              [_vm._v(_vm._s(lang.displayName))]
            )
          }),
          0
        ),
      ]),
      _vm.$route.name !== "Complete"
        ? _c("div", { staticClass: "nav-dropdown currency" }, [
            _c("div", { staticClass: "nav-dropdown__display" }, [
              _c("i", { staticClass: "owl-symbol-money-usd" }),
              _c(
                "span",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: this["hotel/hotel"].name,
                      expression: "this['hotel/hotel'].name",
                    },
                  ],
                },
                [
                  !_vm.isMobile
                    ? [
                        _vm._v(
                          "\n              " +
                            _vm._s(_vm.selectedCurrency) +
                            "\n            "
                        ),
                      ]
                    : _vm._e(),
                ],
                2
              ),
            ]),
            _c(
              "div",
              { staticClass: "nav-dropdown__list" },
              [
                _vm._l(_vm.currencySelectable, function (currency) {
                  return [
                    _c(
                      "li",
                      {
                        key: currency.code,
                        staticClass: "text-sm",
                        class: { actived: _vm.isSelectedCurrency(currency) },
                        on: {
                          click: function ($event) {
                            return _vm.onSelectCurrency(currency.code)
                          },
                        },
                      },
                      [_vm._v(_vm._s(currency.code))]
                    ),
                  ]
                }),
              ],
              2
            ),
          ])
        : _vm._e(),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }