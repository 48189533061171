var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass:
        "hotel-order__item px-16 py-24 flex flex-col sm:flex-row justify-between items-start",
    },
    [
      _c("div", { staticClass: "hotel-order__item__content mb-24 sm:mb-0" }, [
        _c("h5", { staticClass: "text-darkest mb-4" }, [
          _vm._v(
            "\n      " +
              _vm._s(
                _vm.$convertI18nObj(_vm.plan.plan_name_i18n, _vm.plan.plan_name)
              ) +
              "\n    "
          ),
        ]),
        _c("div", { staticClass: "text-sm mb-16 text-grey-darker" }, [
          _vm._v(_vm._s(_vm.plan.date)),
        ]),
      ]),
      _c("div", { staticClass: "hotel-order__item__amount w-full sm:w-auto" }, [
        _c("table", { staticClass: "w-full sm:w-auto" }, [
          _c("tr", [
            _c("td", { staticClass: "pb-8" }, [
              _vm._v(_vm._s(_vm.$t("quantity")) + "："),
            ]),
            _vm.plan.unit
              ? _c("td", { staticClass: "pb-8 text-right" }, [
                  _vm._v(
                    _vm._s(
                      _vm.$t("orderUnit", {
                        stock: _vm.plan.qty,
                        unit: _vm.$convertI18nObj(
                          _vm.plan.unit_i18n,
                          _vm.plan.unit
                        ),
                      })
                    )
                  ),
                ])
              : _c("td", { staticClass: "pb-8 text-right" }, [
                  _vm._v(
                    _vm._s(_vm.plan.qty) +
                      " " +
                      _vm._s(_vm.$tc("room", _vm.plan.qty))
                  ),
                ]),
          ]),
          _vm.hasExtraPersons
            ? _c("tr", [
                _c("td", { staticClass: "pb-8" }, [
                  _vm._v(_vm._s(_vm.$t("extraReception.addPerson")) + "："),
                ]),
                _c("td", { staticClass: "pb-8 text-right" }, [
                  _vm._v(
                    _vm._s(
                      `+${_vm.plan.extra_persons.adults || 0} ${_vm.$t(
                        "extraReception.adult"
                      )}, +${_vm.plan.extra_persons.children || 0} ${_vm.$t(
                        "extraReception.child"
                      )}`
                    )
                  ),
                ]),
              ])
            : _vm._e(),
          _c("tr", [
            _c("td", [_vm._v(_vm._s(_vm.$t("total")) + "：")]),
            _c("td", { staticClass: "text-right" }, [
              _c(
                "span",
                { staticClass: "text-xs" },
                [
                  _vm._v(_vm._s(_vm.orderInfo.currency) + " "),
                  _c(
                    "big",
                    { staticClass: "text-base text-blue font-medium" },
                    [
                      _vm._v(
                        _vm._s(
                          _vm._f("numeral")(_vm.totalPriceIncludeExtraPersons)
                        )
                      ),
                    ]
                  ),
                ],
                1
              ),
            ]),
          ]),
          _c("tr", [
            _c("td"),
            _c("td", { staticClass: "text-right" }, [
              _c("span", { staticClass: "block mt-8" }, [
                _vm._v(
                  "(" + _vm._s(_vm.$t("room_price_has_tax_included")) + ")"
                ),
              ]),
            ]),
          ]),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }