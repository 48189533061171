var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.extraReceptionPriceTotal > 0
    ? _c("div", { staticClass: "order-price__row mb-8 leading-normal" }, [
        _c("div", { staticClass: "text-sm text-grey-darkest" }, [
          _vm._v(_vm._s(_vm.$t("extraReception.addPerson"))),
        ]),
        _c("div", { staticClass: "text-sm text-grey-darker text-right" }, [
          _c("span", { staticClass: "text-grey-darkest" }, [
            _vm._v(
              _vm._s(_vm.selectedCurrency) +
                " " +
                _vm._s(_vm._f("currency")(_vm.extraReceptionPriceTotal))
            ),
          ]),
          _c(
            "span",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: !_vm.isCurrencyMatchHotelCurrency,
                  expression: "!isCurrencyMatchHotelCurrency",
                },
              ],
              staticClass: "text-sm text-grey-dark block mt-4",
            },
            [
              _vm._v(
                "( " +
                  _vm._s(_vm.hotelHotelOriginCurrency) +
                  " " +
                  _vm._s(
                    _vm._f("currency")(_vm.extraReceptionPriceTotal, {
                      outputCurrency: _vm.hotelHotelOriginCurrency,
                    })
                  ) +
                  " )"
              ),
            ]
          ),
        ]),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }