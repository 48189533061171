var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("main", { staticClass: "not-found" }, [
    _c("div", { staticClass: "container text-center" }, [
      _c("h1", { staticClass: "mb-16" }, [
        _vm._v("Sorry, Hotel Page Not Found"),
      ]),
      _c("figure", [
        _c("img", {
          attrs: {
            src: require("@/assets/img/default-room-brandless.jpg"),
            alt: "hotelNotFound",
          },
        }),
      ]),
      _vm.isOwlTingDomain
        ? _c("span", { staticClass: "block text-center text-sm" }, [
            _vm._v("\n      Powered by "),
            _c(
              "a",
              {
                staticClass: "text-blue font-bold no-underline",
                attrs: { href: "https://www.owlting.com/owlnest" },
              },
              [_vm._v("OwlNest, OwlTing Travel Service")]
            ),
          ])
        : _vm._e(),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }