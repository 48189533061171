var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "modal__mobile-orders" }, [
    _c(
      "div",
      {
        staticClass:
          "modal__mobile-orders__head p-16 border-b-1 border-grey-lighter",
      },
      [
        _c("h2", { staticClass: "text-grey-darker font-medium" }, [
          _vm._v(_vm._s(_vm.$t("orderDetail"))),
        ]),
        _c("span", { staticClass: "text-grey-dark" }, [
          _vm._v(_vm._s(this["hotel/hotel"].name)),
        ]),
      ]
    ),
    _c(
      "div",
      { staticClass: "modal__mobile-orders__body" },
      [
        _vm._l(this["order/plans"], function (plan) {
          return _c("OrderPlan", { key: plan.id, attrs: { plan: plan } })
        }),
        _vm._l(this["order/addons"], function (addon) {
          return _c("OrderAddon", { key: addon.id, attrs: { addon: addon } })
        }),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.hasPlan,
                expression: "hasPlan",
              },
            ],
            staticClass: "modal__mobile-orders__body__total p-16",
          },
          [
            _vm.hasExtraTaxFeeConfig
              ? [
                  _c(
                    "div",
                    {
                      staticClass:
                        "order-total__desktop__footer__row text-sm text-grey-darker",
                    },
                    [
                      _c("label", [
                        _vm._v(
                          "\n            " +
                            _vm._s(_vm.$t("extraFeesCharge")) +
                            "\n            "
                        ),
                        _c("i", {
                          directives: [
                            {
                              name: "tippy",
                              rawName: "v-tippy",
                              value: {
                                html: "#extra-fees-list-modal",
                                interactive: true,
                                theme: "light",
                              },
                              expression:
                                "{ html: '#extra-fees-list-modal', interactive : true, theme: 'light' }",
                            },
                          ],
                          staticClass: "owl-status-circle-info",
                        }),
                        _c(
                          "div",
                          {
                            directives: [
                              { name: "tippy-html", rawName: "v-tippy-html" },
                            ],
                            staticClass: "hidden",
                            attrs: { id: "extra-fees-list-modal" },
                          },
                          _vm._l(_vm.extraFees, function (fee) {
                            return _c(
                              "div",
                              {
                                key: `fee-config-${fee.id}`,
                                staticClass: "text-sm text-grey-darker flex",
                              },
                              [
                                _c("label", [_vm._v(_vm._s(fee.name) + ": ")]),
                                _c("span", [
                                  _vm._v(
                                    _vm._s(_vm.selectedCurrency) +
                                      " " +
                                      _vm._s(_vm._f("currency")(fee.price))
                                  ),
                                ]),
                              ]
                            )
                          }),
                          0
                        ),
                      ]),
                      _c(
                        "span",
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.selectedCurrency) +
                              " "
                          ),
                          _c("big", { staticClass: "text-blue font-bold" }, [
                            _vm._v(_vm._s(_vm._f("currency")(_vm.totalFee))),
                          ]),
                        ],
                        1
                      ),
                    ]
                  ),
                ]
              : _vm._e(),
            _c(
              "div",
              {
                staticClass:
                  "modal__mobile-orders__body__total__row text-grey-darkest",
              },
              [
                _c("label", { staticClass: "text-grey-darkest" }, [
                  _vm._v(
                    "\n          " + _vm._s(_vm.$t("total")) + "\n          "
                  ),
                  !_vm.hasExtraTaxFeeConfig
                    ? _c("small", [
                        _vm._v(
                          "(" +
                            _vm._s(_vm.$t("room_price_has_tax_included")) +
                            ")"
                        ),
                      ])
                    : _vm._e(),
                ]),
                _c(
                  "span",
                  { staticClass: "text-sm text-grey-darkest" },
                  [
                    _vm._v("\n          " + _vm._s(_vm.selectedCurrency) + " "),
                    _c(
                      "big",
                      { staticClass: "text-xl text-blue font-medium" },
                      [
                        _vm._v(
                          _vm._s(_vm._f("currency")(this["order/totalPrice"]))
                        ),
                      ]
                    ),
                  ],
                  1
                ),
              ]
            ),
            this["order/ifDepositRate"]
              ? [
                  _c(
                    "div",
                    {
                      staticClass:
                        "modal__mobile-orders__body__total__row text-xs text-grey-dark",
                    },
                    [
                      _c("label", [_vm._v(_vm._s(_vm.$t("depositTotal")))]),
                      _c("span", [
                        _vm._v(
                          _vm._s(_vm.selectedCurrency) +
                            " " +
                            _vm._s(
                              _vm._f("currency")(this["order/depositTotal"])
                            )
                        ),
                      ]),
                    ]
                  ),
                  _c(
                    "div",
                    {
                      staticClass:
                        "modal__mobile-orders__body__total__row text-xs text-grey-dark",
                    },
                    [
                      _c("label", [_vm._v(_vm._s(_vm.$t("remainTotal")))]),
                      _c("span", [
                        _vm._v(
                          _vm._s(_vm.selectedCurrency) +
                            " " +
                            _vm._s(
                              _vm._f("currency")(this["order/remainTotal"])
                            )
                        ),
                      ]),
                    ]
                  ),
                ]
              : _vm._e(),
          ],
          2
        ),
      ],
      2
    ),
    _c(
      "div",
      {
        staticClass:
          "modal__mobile-orders__footer flex items-center justify-between",
        on: {
          click: function ($event) {
            return _vm.$emit("close")
          },
        },
      },
      [
        _c("div", { staticClass: "modal__mobile-orders__footer__order-info" }, [
          _c(
            "div",
            {
              staticClass:
                "modal__mobile-orders__footer__order-info__date text-sm",
            },
            [
              _c("i", { staticClass: "owl-calendar mr-8 text-grey text-xl" }),
              _c("span", [
                _vm._v(
                  _vm._s(_vm._f("dayFormat")(this["search/dateRange"].start))
                ),
              ]),
              _c("span", [_vm._v(" - ")]),
              _c("span", [
                _vm._v(
                  _vm._s(_vm._f("dayFormat")(this["search/dateRange"].end))
                ),
              ]),
            ]
          ),
          _c(
            "div",
            {
              staticClass:
                "modal__mobile-orders__footer__order-info__participants text-sm",
            },
            [
              _c("i", { staticClass: "owl-user-group mr-8 text-grey text-xl" }),
              _c("span", [
                _vm._v(
                  _vm._s(this["search/people"].adult) +
                    " " +
                    _vm._s(_vm.$tc("adult", this["search/people"].adult)) +
                    ", " +
                    _vm._s(this["search/people"].child) +
                    " " +
                    _vm._s(_vm.$tc("child", this["search/people"].child)) +
                    ", " +
                    _vm._s(this["search/people"].infant) +
                    " " +
                    _vm._s(_vm.$tc("infant", this["search/people"].infant))
                ),
              ]),
            ]
          ),
        ]),
        _c(
          "button",
          { staticClass: "btn btn-solid btn-grey-lighter py-8 px-16" },
          [_vm._v(_vm._s(_vm.$t("close")))]
        ),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }