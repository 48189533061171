var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "room-card border-b-1 border-grey-lighter mb-40 md:mb-80",
      attrs: { "data-room-id": _vm.room.id },
    },
    [
      _c("h4", { staticClass: "mb-16 text-grey-darkest" }, [
        _vm._v(_vm._s(_vm.room.name)),
      ]),
      _c(
        "div",
        { staticClass: "room-card__brief flex flex-col md:flex-row flex-wrap" },
        [
          _c(
            "div",
            {
              staticClass:
                "room-card__brief__gallery w-full md:w-1/2 lg:w-full xl:w-1/2 relative",
            },
            [
              _vm.isMobile && _vm.isImage
                ? [
                    _c("imageSwiper", {
                      attrs: { photos: _vm.room.images },
                      on: { showPhoto: _vm.openPhotoSwipe },
                    }),
                  ]
                : [
                    _c(
                      "div",
                      {
                        staticClass:
                          "w-full h-full bg-cover bg-center bg-grey-lightest room-card__brief__gallery__cover",
                        style: _vm.roomCover,
                        on: {
                          click: function ($event) {
                            $event.stopPropagation()
                            return _vm.openPhotoSwipe()
                          },
                        },
                      },
                      [
                        _vm.isImage
                          ? _c(
                              "div",
                              {
                                staticClass:
                                  "room-card__brief__gallery__cover__mask",
                              },
                              [_c("i", { staticClass: "owl-search" })]
                            )
                          : _vm._e(),
                      ]
                    ),
                  ],
              _vm.isImage
                ? _c("photoSwipe", {
                    ref: "photoSwipe",
                    attrs: { photos: _vm.room.images },
                    on: { close: _vm.photoSwipeCloseHandler },
                  })
                : _vm._e(),
            ],
            2
          ),
          _c(
            "div",
            {
              staticClass:
                "room-card__brief__content w-full md:w-1/2 lg:w-full xl:w-1/2 md:pl-24 py-16",
            },
            [
              _c(
                "div",
                {
                  staticClass:
                    "room-card__brief__content__price-tag os-tag os-tag-full os-tag-outline os-tag-grey mb-16 static md:absolute lg:static xl:absolute",
                },
                [
                  _c("small", { staticClass: "mr-4" }, [
                    _vm._v(_vm._s(_vm.selectedCurrency)),
                  ]),
                  _c("span", [
                    _vm._v(_vm._s(_vm._f("currency")(_vm.room.lowest_price))),
                  ]),
                  _c("small", { staticClass: "ml-4" }, [
                    _vm._v(_vm._s(_vm.$t("up"))),
                  ]),
                ]
              ),
              _c("div", { staticClass: "room-card__brief__content__row" }, [
                _c(
                  "div",
                  { staticClass: "content-group" },
                  [
                    _c("label", [_vm._v(_vm._s(_vm.$t("bedType")))]),
                    _vm._l(_vm.room.beds, function (bed, index) {
                      return _c("div", { key: index }, [
                        _vm._v(
                          "\n            " +
                            _vm._s(bed.name) +
                            " x " +
                            _vm._s(bed.count) +
                            "\n          "
                        ),
                      ])
                    }),
                  ],
                  2
                ),
              ]),
              _c(
                "div",
                { staticClass: "room-card__brief__content__row flex" },
                [
                  _vm.room.max_people
                    ? _c("div", { staticClass: "content-group mr-24" }, [
                        _c("label", [_vm._v(_vm._s(_vm.$t("peopleLimit")))]),
                        _c("span", [
                          _vm._v(
                            _vm._s(_vm.room.max_people) +
                              " " +
                              _vm._s(_vm.$tc("person", _vm.room.max_people))
                          ),
                        ]),
                      ])
                    : _vm._e(),
                  _vm.room.area
                    ? _c("div", { staticClass: "content-group" }, [
                        _c("label", [_vm._v(_vm._s(_vm.$t("area")))]),
                        _c("span", [
                          _vm._v(
                            _vm._s(_vm.room.area) +
                              " " +
                              _vm._s(_vm.room.area_unit)
                          ),
                        ]),
                      ])
                    : _vm._e(),
                ]
              ),
              _c("div", { staticClass: "room-card__brief__content__row" }, [
                _vm.isFeatures
                  ? _c("div", { staticClass: "content-group" }, [
                      _c("label", [
                        _vm._v(
                          _vm._s(_vm.$tc("features", _vm.top4features.length))
                        ),
                      ]),
                      _c(
                        "ul",
                        { staticClass: "feature-list flex flex-wrap mb-8" },
                        _vm._l(_vm.top4features, function (feature, index) {
                          return _c(
                            "li",
                            { key: index, staticClass: "mt-4 mr-8" },
                            [
                              feature.icon_url
                                ? _c("img", {
                                    staticClass: "room-card__feature-icon",
                                    attrs: { src: feature.icon_url },
                                  })
                                : feature.name
                                ? _c("i", {
                                    staticClass: "be-icon",
                                    class: feature.name,
                                  })
                                : _vm._e(),
                              _c("span", { staticClass: "align-text-top" }, [
                                _vm._v(
                                  "\n                " +
                                    _vm._s(feature.text) +
                                    "\n              "
                                ),
                              ]),
                            ]
                          )
                        }),
                        0
                      ),
                      _vm.isFeatureMoreThan4
                        ? _c(
                            "a",
                            {
                              staticClass:
                                "more-features text-sm cursor-pointer",
                              on: { click: _vm.showMoreFeatures },
                            },
                            [
                              _c("i", { staticClass: "owl-status-add mr-4" }),
                              _c("span", [_vm._v(_vm._s(_vm.$t("more")))]),
                            ]
                          )
                        : _vm._e(),
                    ])
                  : _vm._e(),
              ]),
            ]
          ),
        ]
      ),
      _c(
        "div",
        { staticClass: "room-card_plans" },
        _vm._l(_vm.sortedPlans, function (plan) {
          return _c(
            "RoomPlan",
            _vm._g(
              {
                key: plan.id,
                attrs: {
                  plan: plan,
                  room: _vm.room,
                  stock: _vm.room.stock_count,
                  addedStock: _vm.addedStock,
                },
                on: { stockChange: _vm.hasAdded },
              },
              _vm.$listeners
            )
          )
        }),
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }