var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass:
        "order-addon px-16 pt-24 pb-16 font-normal border-grey-lighter relative",
    },
    [
      _c("div", { staticClass: "order-addon__row mb-16 justify-between" }, [
        _c("label", { staticClass: "text-xs text-orange font-medium block" }, [
          _vm._v(_vm._s(_vm.$t("addAddons"))),
        ]),
      ]),
      _c("h5", { staticClass: "text-grey-darkest mb-8 font-medium" }, [
        _vm._v(_vm._s(_vm.addonName)),
      ]),
      _vm._l(_vm.addon.items, function (item, index) {
        return _c(
          "div",
          {
            key: _vm.addon.id + "-" + index,
            staticClass: "order-addon__row flex justify-between",
          },
          [
            _c("label", { staticClass: "text-grey-darkest mr-8" }, [
              _vm._v(_vm._s(_vm.$dayjs(item.date).format("YYYY-MM-DD"))),
            ]),
            _c(
              "span",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: !_vm.isGoods(item),
                    expression: "!isGoods(item)",
                  },
                ],
                staticClass: "text-grey-dark mr-auto",
              },
              [_vm._v(_vm._s(_vm.parseTime(item.session)))]
            ),
            _c("span", { staticClass: "mr-24 flex-1 text-right" }, [
              _vm._v("x " + _vm._s(item.qty) + " "),
              _c("small", [_vm._v(_vm._s(item.unit))]),
            ]),
            _c("span", [
              _vm._v(
                _vm._s(_vm.selectedCurrency) + " " + _vm._s(_vm.rowTotal(item))
              ),
            ]),
            _c("span", [
              _c(
                "button",
                {
                  staticClass: "removeAddonBtn",
                  on: {
                    click: function ($event) {
                      return _vm.onRemoveAddon(index)
                    },
                  },
                },
                [_c("i", { staticClass: "owl-trash" })]
              ),
            ]),
          ]
        )
      }),
      _c("div", { staticClass: "order-addon__row justify-between mt-16" }, [
        _c("label", { staticClass: "text-grey-dark" }, [
          _vm._v(_vm._s(_vm.$t("total"))),
        ]),
        _c(
          "span",
          [
            _vm._v("\n      " + _vm._s(_vm.selectedCurrency) + "\n      "),
            _c("big", { staticClass: "text-blue font-bold" }, [
              _vm._v(_vm._s(_vm._f("numeral")(_vm.addonTotal))),
            ]),
          ],
          1
        ),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }