var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "mb-16 pb-16 border-b-1 border-grey-lighter" },
    [
      _c("p", { staticClass: "font-medium text-base mb-16" }, [
        _vm._v(_vm._s(_vm.plan.roomName)),
      ]),
      _c("div", { staticClass: "flex justify-between mb-16" }, [
        _c("p", [_vm._v(_vm._s(_vm.plan.planTitle))]),
        _c("p", [_vm._v("x 1 " + _vm._s(_vm.$t("room")))]),
      ]),
      _c("div", { staticClass: "flex justify-between mb-16" }, [
        _c("span", [
          _vm._v(
            _vm._s(_vm.selectedCurrency) +
              " " +
              _vm._s(_vm._f("currency")(_vm.roomTotalPrice))
          ),
        ]),
        _c("span", [
          _vm._v(
            "x " +
              _vm._s(this["search/nights"]) +
              " " +
              _vm._s(_vm.$t("night", this["search/nights"]))
          ),
        ]),
      ]),
      _vm.hasExtraReception
        ? _c("div", { staticClass: "flex justify-between mb-16" }, [
            _c("span", [
              _vm._v(
                _vm._s(_vm.selectedCurrency) +
                  " " +
                  _vm._s(_vm._f("currency")(_vm.extraReceptionTotalPrice))
              ),
            ]),
            _c("span", [
              _vm._v(
                _vm._s(
                  `+ ${_vm.extraReceptionOnIndex.adults} ${_vm.$t(
                    "extraReception.adult"
                  )}, + ${_vm.extraReceptionOnIndex.children} ${_vm.$t(
                    "extraReception.child"
                  )}`
                )
              ),
            ]),
          ])
        : _vm._e(),
      _c("div", { staticClass: "flex justify-between" }, [
        _c("span", [_vm._v(_vm._s(_vm.$t("total")))]),
        _c("span", { staticClass: "font-bold" }, [
          _vm._v(
            _vm._s(_vm.selectedCurrency) +
              " " +
              _vm._s(_vm._f("currency")(_vm.totalPrice))
          ),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }