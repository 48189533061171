var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return !_vm.hasAgreeCookie
    ? _c("div", { attrs: { id: "cookie-notification" } }, [
        _c(
          "div",
          {
            staticClass:
              "container flex flex-col sm:flex-row items-end sm:items-center",
          },
          [
            _c("div", { staticClass: "content mb-8 sm:mb-0 sm:mr-32" }, [
              _c("p", [
                _vm._v(_vm._s(_vm.$t("cookie.content")) + "\n        "),
                _c(
                  "a",
                  {
                    attrs: {
                      href: "https://www.owlting.com/about/cookie_policy",
                      target: "_blank",
                    },
                  },
                  [_vm._v(_vm._s(_vm.$t("cookie.more")))]
                ),
              ]),
            ]),
            _c(
              "button",
              {
                staticClass:
                  "btn btn-sm btn-solid btn-blue flex-no-shrink px-16",
                on: { click: _vm.agreeCookie },
              },
              [_vm._v(_vm._s(_vm.$t("cookie.agree")))]
            ),
          ]
        ),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }